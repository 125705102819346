import React from "react";
import styles from "./index.less";
import PageHeader from "@/components/pageHeader";
import { router } from "dva";
import { isInQiankun } from "@/utils/qiankun";
const { Link } = router;

interface IErrorProps {}

const Error: React.FC<IErrorProps> = () => (
  <div>
    {!isInQiankun() && <PageHeader></PageHeader>}
    <div className={styles.main}>
      <img
        src="https://hema-web.oss-cn-hangzhou.aliyuncs.com/stadiums/resource/%E7%BC%BA%E7%9C%81%E9%A1%B5-%E6%97%A0%E6%9D%83%E9%99%90%402x.png"
        alt=""
      />
      <div className={styles.top}>抱歉，你访问的页面不存在</div>
      {!isInQiankun() && (
        <div className={styles.bottom}>
          请返回<Link to="/">首页</Link>继续浏览
        </div>
      )}
    </div>
  </div>
);

export default Error;
