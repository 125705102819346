import { message } from "antd";
import _request from "@/utils/request";
import {
  API_PREFIX,
  USER_PREFIX,
  WX_API_PREFIX,
  wenlvSignature,
  API_HOST,
} from "@/constants";
import moment from "moment";
import qs from "qs";
import { isInQiankun } from "./qiankun";

interface IObjectAny {
  [propName: string]: any;
}

export type Result<T> =
  | {
      success: true;
      data: T;
      response: any; // 原始数据
    }
  | {
      success: false;
      err: Error;
      message: string;
      data?: any;
    };

const addGetParams = (url: string, data = {}) => {
  const params = qs.stringify(data);
  return params ? `${url}?${params}` : url;
};

// url 接口, data 入参, options 配置
export const request = {
  get: (url: string, data = {}) => {
    return _request(`${API_PREFIX}${addGetParams(url, data)}`, {
      method: "GET",
    });
  },
  post: (url: string, data = {}, options?: any) => {
    let pre = "";
    if (isInQiankun() && url.includes("wechat/QRCode")) pre = "/school-manager";
    return _request(`${pre || API_PREFIX}${url}`, {
      method: "POST",
      body: options?.stringify ? JSON.stringify(data) : data,
    });
  },
  put: (url: string, data = {}, options?: any) => {
    return _request(`${API_PREFIX}${url}`, {
      method: "PUT",
      body: options?.stringify ? JSON.stringify(data) : data,
    });
  },
  delete: (url: string, data = {}) => {
    return _request(`${API_PREFIX}${addGetParams(url, data)}`, {
      method: "DELETE",
    });
  },
};
// 登录获取cookie专用 url 接口, data 入参, options 配置
export const userRequest = {
  get: (url: string, data = {}) => {
    return _request(`${USER_PREFIX}${addGetParams(url, data)}`, {
      method: "GET",
    });
  },
  post: (url: string, data = {}, options?: any) => {
    return _request(`${USER_PREFIX}${url}`, {
      method: "POST",
      body: options?.stringify ? JSON.stringify(data) : data,
    });
  },
  put: (url: string, data = {}, options?: any) => {
    return _request(`${USER_PREFIX}${url}`, {
      method: "PUT",
      body: options?.stringify ? JSON.stringify(data) : data,
    });
  },
  delete: (url: string, data = {}) => {
    return _request(`${USER_PREFIX}${addGetParams(url, data)}`, {
      method: "DELETE",
    });
  },
};
// 微信小程序页面 url 接口, data 入参, options 配置
export const wxRequest = {
  get: (url: string, data = {}) => {
    const token = localStorage.getItem("wxToken");
    return _request(`${WX_API_PREFIX}${addGetParams(url, data)}`, {
      method: "GET",
      headers: {
        token,
      },
    });
  },
  post: (url: string, data = {}, options?: any) => {
    const token = localStorage.getItem("wxToken");
    return _request(`${WX_API_PREFIX}${url}`, {
      method: "POST",
      body: options?.stringify ? JSON.stringify(data) : data,
      headers: {
        token,
      },
    });
  },
  put: (url: string, data = {}, options?: any) => {
    const token = localStorage.getItem("wxToken");
    return _request(`${WX_API_PREFIX}${url}`, {
      method: "PUT",
      body: options?.stringify ? JSON.stringify(data) : data,
      headers: {
        token,
      },
    });
  },
  delete: (url: string) => {
    const token = localStorage.getItem("wxToken");
    return _request(`${WX_API_PREFIX}${url}`, {
      method: "DELETE",
      headers: {
        token,
      },
    });
  },
};

export const wenlvRequest = {
  get: (url: string, data = {}) => {
    return _request(
      `${API_HOST}${addGetParams(url, data)}?${wenlvSignature()}`,
      {
        method: "GET",
      }
    );
  },
  post: (url: string, data = {}, options?: any) => {
    return _request(`${API_HOST}${url}?${wenlvSignature()}`, {
      method: "POST",
      body: options?.stringify
        ? JSON.stringify({ params: data })
        : { params: data },
    });
  },
  put: (url: string, data = {}, options?: any) => {
    return _request(`${API_HOST}${url}?${wenlvSignature()}`, {
      method: "PUT",
      body: options?.stringify
        ? JSON.stringify({ params: data })
        : { params: data },
    });
  },
  delete: (url: string) => {
    return _request(`${API_HOST}${url}?${wenlvSignature()}`, {
      method: "DELETE",
    });
  },
};

export type IMessageType =
  | "success"
  | "error"
  | "info"
  | "warning"
  | "warn"
  | "loading";

// 全局提示
export const tips = (type: IMessageType, title: string) => {
  message.destroy();
  (message as any)[type](title);
};

// 若传入 format='format' 则走规范
export const dateFormat = (
  date: string | number | Date,
  format = "yyyy-MM-dd hh:mm:ss TT"
): string => {
  if (!date) {
    date = new Date();
  }
  if (typeof date === "string" && /^\d+$/.test(date)) {
    date = new Date(+date);
  }
  if (typeof date === "number") {
    date = new Date(date);
  }
  if (typeof date !== "number" && !(date instanceof Date)) {
    date = date.replace(/年|月/g, "-").replace(/日/g, "");
    date = new Date(date);
  }

  const duration = Date.now() - date.getTime();
  const level1 = 60 * 1000; // 1 分钟
  const level2 = 60 * 60 * 1000; // 1 小时
  const level3 = 24 * 60 * 60 * 1000; // 1 天
  const level4 = 2 * 24 * 60 * 60 * 1000; // 2天

  if (format === "default") {
    if (duration < level1) {
      return "刚刚";
    }
    if (duration >= level1 && duration < level2) {
      return `${Math.round(duration / level1)}分钟前`;
    }
    if (duration >= level2 && duration < level3) {
      return `${Math.round(duration / level2)}小时前`;
    }
    if (duration >= level3 && duration < level4) {
      format = "昨天 hh:mm";
    }
    // 判断是否过年了
    const _date = new Date();
    const _year = _date.getFullYear();
    if (new Date(`${_year}-01-01`).getTime() <= date.getTime()) {
      format = "MM月dd日 hh:mm";
    } else {
      format = "yyyy年MM月dd日 hh:mm";
    }
  }

  const o: IObjectAny = {
    "M+": date.getMonth() + 1, // 月份
    "d+": date.getDate(), // 日
    "h+": date.getHours(), // 小时 24进制
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
    "T+": date.getHours() < 12 ? "AM" : "PM",
    "H+": date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时 12进制
  };
  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      String(date.getFullYear()).substr(4 - RegExp.$1.length)
    );
  }
  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(String(o[k]).length)
      );
    }
  });
  return format;
};

export const getStringLength = (str: string) => {
  return str.replace(/[\u0391-\uFFE5]/gim, "aa").length;
};

export const isMobile = () => {
  const userAgentInfo = navigator.userAgent;
  const Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
  let flag = false;

  for (const v of Agents) {
    if (userAgentInfo.includes(v)) {
      flag = true;
      break;
    }
  }
  return flag;
};

export const formatTime = (
  startTime?: number,
  endTime?: number,
  format?: string
) => {
  if (startTime && endTime) {
    const sTime = moment(startTime)
      .format(format || "YYYY/MM/DD HH:mm")
      .split(" ");
    const eTime = moment(endTime)
      .format(format || "YYYY/MM/DD HH:mm")
      .split(" ");
    if (sTime[0] === eTime[0]) {
      return `${sTime[0]} ${sTime[1]}-${eTime[1]}`;
    } else {
      return `${sTime[0]} ${sTime[1]} ~ ${eTime[0]} ${eTime[1]}`;
    }
  }

  return "- -";
};

export const getJsonDetail = (url: string) => {
  return new Promise<any>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.onload = function () {
      if (this.status == 200 || this.status == 204) {
        resolve(this.response ? JSON.parse(this.response) : {});
      } else {
        reject();
      }
    };
    xhr.send();
  });
};
export const downloadFile = (url:string,name:string) => {
  const x = new window.XMLHttpRequest();
  x.open('GET', url, true);
  x.responseType = 'blob';
  x.onload = () => {
      const href = window.URL.createObjectURL(x.response);
      const a = document.createElement('a');
      a.href = href;
      a.download = name;
      a.click();
  };
  x.send();
}