import { Button, Input, message, Modal } from "antd";
import React, { Component } from "react";

import styles from "./index.less";

type LocationSelectComponentProps = {
  value?: any;
  onChange?: Function;
};

type LocationSelectComponentState = {
  visible: boolean;
};

interface MapWrapperProps extends LocationSelectComponentProps {
  onCancel: () => void;
}

type MapWrapperState = {
  position?: {
    address?: string;
    location?: string;
    province?: string;
    cityId?: string;
  };
  marker: any;
};

const { Search } = Input;

class MapWrapper extends Component<MapWrapperProps, MapWrapperState> {
  map: any;

  constructor(props: any) {
    super(props);
    this.state = {
      position: undefined,
      marker: null,
    };
    this.map = null;
  }

  componentDidMount() {
    const { value } = this.props;

    setTimeout(() => {
      try {
        this.map = new AMap.Map("amap", {
          zoom: 15,
        });

        if (value) {
          this.setState({
            position: value,
          });

          const location = value.location.split(",");
          console.log("location", location);

          const marker = new AMap.Marker({
            position: location,
          });
          this.map.add(marker);
          this.map.setCenter(location);
          this.setState({ marker });
        }

        AMap.plugin(["AMap.Autocomplete", "AMap.PlaceSearch"], () => {
          const autoOptions = {
            input: "search",
          };
          var autocomplete = new AMap.Autocomplete(autoOptions);
          // console.log("map", this.map);

          var placeSearch = new AMap.PlaceSearch({ map: this.map });

          AMap.event.addListener(autocomplete, "select", (e: any) => {
            // console.log("e", e);

            placeSearch.setCity(e.poi.adcode);
            placeSearch.search(e.poi.name);
          });

          AMap.event.addListener(placeSearch, "markerClick", this.markerClick);
        });
      } catch (error) {}
    }, 200);
  }

  markerClick = (e: any) => {
    const { marker } = this.state;
    if (marker) {
      this.map.remove(marker);
    }
    console.log("position", e.data);
    this.setState({
      position: {
        address: e.data.cityname + e.data.adname + e.data.name,
        location: `${e.data.location.lng},${e.data.location.lat}`,
        province: e.data.pcode,
        cityId: e.data.adcode.slice(0, 4) + "00",
      },
      marker: null,
    });
  };

  onOk = () => {
    const { onCancel, onChange } = this.props;
    const { position } = this.state;
    if (!position) {
      message.warning("请先选择场馆位置");
      return;
    }

    onChange && onChange(position);

    onCancel();
  };

  render() {
    const { onCancel } = this.props;
    const { position } = this.state;

    return (
      <div>
        <div className={styles.header}>
          <div>场馆位置：{position?.address || "-"}</div>
          <div>
            <Button onClick={onCancel}>取消</Button>
            <Button type="primary" onClick={this.onOk}>
              确定
            </Button>
          </div>
        </div>
        <div id="amap" className={styles.map}>
          <Search
            id="search"
            className={styles.searchInput}
            placeholder="搜索位置、公交站、地铁站"
          />
        </div>
      </div>
    );
  }
}

class LocationSelect extends Component<
  LocationSelectComponentProps,
  LocationSelectComponentState
> {
  constructor(props: LocationSelectComponentProps) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  onShowLocation = () => {
    this.setState({
      visible: true,
    });
  };

  onCancelModal = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { visible } = this.state;
    const { value } = this.props;
    return (
      <div>
        {!value && <span className={styles.emptyLocation}>暂未指定</span>}
        <a onClick={this.onShowLocation}>{value ? value.address : "定位"}</a>

        <Modal
          open={visible}
          closable={false}
          footer={null}
          bodyStyle={{ padding: 0 }}
          width={1024}
        >
          <MapWrapper
            {...this.props}
            onCancel={this.onCancelModal}
          ></MapWrapper>
        </Modal>
      </div>
    );
  }
}

export default LocationSelect;
