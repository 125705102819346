import MView from "./view";
import MRowTable from "./rowTable";
import MColTable from "./colTable";

// export { MViewProps } from './view';
// export { MRowTableProps } from './rowTable';
// export { MColTableProps } from './colTable';

MView.RowTable = MRowTable;
MView.ColTable = MColTable;

export default MView;
