import request from "./request";
import store from "./storage";
import { isInQiankun } from "./qiankun";

// 加载script的状态
const loadingScriptStatusMap: { [key: string]: boolean } = {
  // [url]: true | false
};

//清除一级域名下的cookie
const clearAllCookie = () => {
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  const domain = document.domain.split(".").slice(-2).join(".");
  if (keys) {
    for (let i = keys.length; i--; ) {
      document.cookie = `${keys[i]}=;Domain=.${domain};Path=/`;
    }
  }
};
function hasScriptLoaded(url: string) {
  for (const script of document.scripts as any) {
    if (script.src === url) {
      return true;
    }
  }
  return false;
}

export const loadAsyncScript = (url: string) => {
  if (loadingScriptStatusMap[url] || hasScriptLoaded(url)) return;
  return new Promise((resolve, reject) => {
    var script = document.createElement("script");
    script.src = url;
    document.head.appendChild(script);
    loadingScriptStatusMap[url] = true;
    script.onload = () => {
      loadingScriptStatusMap[url] = false;
      resolve(url);
      script.onload = null;
    };
    script.onerror = (err) => {
      loadingScriptStatusMap[url] = false;
      reject(err);
      script.onerror = null;
    };
  });
};

// 加载百度地图
export const loadBMapScript = () => {
  // 挂载的版本信息 - Sentry 上报使用
  window.versionInfo = {
    VERSION: "0.1.0", // 版本号信息，发布时需要在cms上同步修改
    ENV: "DEV", // TEST、PRE、PROD等 环境信息，需要在cms上设置
    INIT: false,
  };
  window._AMapSecurityConfig = {
    securityJsCode: "78fe0c8f1c6d4474c354a36a3f9c0e85",
  };
  const bmapUrl =
    "https://webapi.amap.com/maps?v=1.4.15&key=54ef2f4d776285bb6f88502e5739b247";
  if (!isInQiankun()) {
    window.onload = () => loadAsyncScript(bmapUrl);
    return;
  }
  loadAsyncScript(bmapUrl);
};

export const onLogout = () => {
  store.clear();
  clearAllCookie();
  location.reload();
};

export default {
  request,
};
