import React, { ReactNode } from "react";
import { Layout } from "antd";
import { MScrollView, MView } from "@/components";
import styles from "./app.less";
import classNames from "classnames";
import { isInQiankun } from "@/utils/qiankun";

interface IAppProps {
  children: ReactNode;
}

const { Content } = Layout;

const App: React.FC<IAppProps> = ({ children }) => {
  return (
    <Layout
      className={classNames(
        styles.layout,
        isInQiankun() && styles.qiankunLayout
      )}
    >
      <Content className={styles.content} id="content">
        <MScrollView>
          <MView className={styles.main}>{children}</MView>
        </MScrollView>
      </Content>
    </Layout>
  );
};

export default App;
