/**
 * @description auth service层
 */

import { request, userRequest } from "@/utils/tool";

/**
 * @description 获取个人用户信息
 */
export async function getUserInfo() {
  return userRequest.get(`/getInfo`);
}
export function getStadiumInfo() {
  return request.get("/vlog/stadiums");
}
export function getOSSSignature(data?: any) {
  return request.get("/ossSignature", data);
}
//logo上传  需要压缩 裁剪
export function uploadLogoApi(data: any) {
  return request.post("/vlog/teams/logoUpload", data);
}
export function login(userInfo: {
  username: string;
  password: string;
  isPublic: number;
}) {
  return userRequest.post("/login", userInfo);
}
export function generateCookie(token: string) {
  return userRequest.post("/generateCookie", { token });
}

export function logOut() {
  return userRequest.post(`/logout`);
}
//手机号登录
export function reqPhoneLogin(data: any) {
  // data.headers = { "Content-Type": "x-www-form-urlencoded" };
  return userRequest.post(
    `/smsLogin?telephone=${data.telephone}&verifyCode=${data.verifyCode}`
  );
}

//修改密码
export function reqRetrievePassword(data: {
  oldPassword: string;
  newPassword: string;
}) {
  return userRequest.put(`/system/user/profile/updatePwd`, data);
}
//找回密码
export function reqEditPassword(data: {
  telephone: string;
  verifyCode: string;
  password: string;
}) {
  return userRequest.put(`/retrievePassword`, data);
}
//获取验证码
export function reqVerifyCode({
  type,
  phone,
}: {
  type: string;
  phone: string;
}) {
  return userRequest.get(
    `/sms/verifyCode/generate?type=${type}&phone=${phone}`
  );
}
//获取账号下关联机构列表
export function getCurrentBizTypeOrg() {
  return request.get("/vlog/users/org/getCurrentBizTypeOrgSelectList");
}
//切换机构
export function switchActivateOrgRequest(orgId: number) {
  return userRequest.get(`/switchActivateOrg?orgId=${orgId}`);
}
