/* eslint-disable no-use-before-define */
import { IrregularRectPoint } from "@/utils/canvasDraw/canvasUtils";
import {
  Model,
  createActionCreaters,
  Effects,
  Reducers,
} from "@pandaxiong1992/dva-ts-wrapper";
import { GlobalState } from "./types";

export type IDrawModelState = Readonly<{
  ballBoxes?: IrregularRectPoint;
  coordinates?: IrregularRectPoint;
  adPlacement?: IrregularRectPoint;
  cropArea?: IrregularRectPoint;
  venueCoordinates?: IrregularRectPoint;
  isShowPrompt: boolean;
  adImage: string;
}>;

interface IReducersPayloads {
  save: void | Partial<IDrawModelState>;
}

interface IEffectsPayloads {
  onChangeState: void;
}

const reducers: Reducers<IDrawModelState, IReducersPayloads> = {
  save(state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
};

const effects: Effects<GlobalState, IEffectsPayloads> = {
  *onChangeState({ payload }, { put }) {
    yield put({
      type: "save",
      payload,
    });
  },
};

const DrawModel: Model<
  IDrawModelState,
  GlobalState,
  IReducersPayloads,
  IEffectsPayloads
> = {
  namespace: "draw",
  state: {
    ballBoxes: undefined,
    coordinates: undefined,
    adPlacement: undefined,
    cropArea: undefined,
    isShowPrompt: false,
    adImage: "",
    venueCoordinates: undefined,
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // eslint-disable-line
    },
  },

  effects,
  reducers,
};

export default DrawModel;

export const drawActions = createActionCreaters(DrawModel);
