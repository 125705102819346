import { useEffect, useState, memo } from "react";
import { Modal, Carousel, message } from "antd";
import classnames from "classnames";
import { connect } from "dva";
import styles from "./index.less";
import React from "react";

function splitArrayIntoGroups(array: any[], N: number) {
  const result = [];

  for (let i = 0; i < array.length; i += N) {
    const group = array.slice(i, i + N);
    result.push(group);
  }

  return result;
}
const pageSize = 6; // 每页显示的元素个数
type chooseOrgModalProps = {
  visible: boolean;
  dispatch: any;
  orgList: any[];
  closable: boolean;
};
const ChooseOrgModal: React.FC<chooseOrgModalProps> = (props) => {
  const [groupList, setGroupList] = useState<any[]>([]);
  const [selectOrg, setSelectOrg] = useState<{ orgId: number }>();
  const { visible, dispatch, orgList, closable = false } = props;
  useEffect(() => {
    if (orgList && orgList.length) {
      const data = splitArrayIntoGroups([...orgList], pageSize);
      setGroupList(data);
    }
  }, [orgList]);
  const handleCancel = () => {
    dispatch({
      type: "user/chooseOrgModalVisible",
      payload: false,
    });
  };

  const handleEnter = () => {
    if (selectOrg && selectOrg.orgId) {
      dispatch({
        type: "user/switchActivateOrg",
        payload: {
          orgId: selectOrg.orgId,
          isLogin: location.pathname.includes("/login"),
        },
      });
    } else {
      message.error("请选择机构");
    }
  };

  return (
    <Modal
      open={visible}
      closable={closable}
      maskClosable={closable}
      footer={null}
      onCancel={handleCancel}
      className={styles.orgModal}
    >
      <div className={styles.orgModalContent}>
        <div className={styles.header}>请选择您要进入的学校</div>
        <div className={styles.content}>
          <Carousel dots={{ dotsClass: styles.dotsOrgClass }}>
            {groupList.map((list, index) => {
              return (
                <div className={styles.orgList} key={index}>
                  {list.map((item: any) => {
                    return (
                      <div
                        className={classnames(
                          styles.orgItem,
                          selectOrg?.orgId === item.orgId && styles.selectOrg
                        )}
                        key={item.orgId}
                        onClick={() => setSelectOrg(item)}
                      >
                        <div className={styles.orgName}>{item.orgName}</div>
                        <div className={styles.parentName}>
                          {item.parentName || "暂无上级机构"}
                        </div>
                        {selectOrg?.orgId === item.orgId && (
                          <img
                            className={styles.selectIcon}
                            src="https://hema-web.oss-cn-hangzhou.aliyuncs.com/school/campus/org-select.png"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className={styles.footer}>
          <div className={styles.enterBtn} onClick={handleEnter}>
            进入系统
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    orgList: state.user.orgList,
    visible: state.user.chooseOrgModal,
  };
};
export default connect(mapStateToProps)(ChooseOrgModal);
