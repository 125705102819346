import store from "@/utils/storage";
import {
  getCurrentBizTypeOrg,
  getUserInfo,
  switchActivateOrgRequest,
} from "@/services/auth";
import history from "@/utils/history";
import getStadium from "@/utils/authorized";
import { isInQiankun } from "@/utils/qiankun";
import { message } from "antd";
interface MethodType {
  take: any;
  put: any;
  select: any;
  call: any;
}
export default {
  namespace: "user",

  state: {
    accountInfo: {},
    stadiumInfo: {},
    newMenu: [],
    deviceTypeList: [],
    orgList: [],
    chooseOrgModal: false,
    isInQiankun: isInQiankun(),
    isFromRace: false,
    raceInfo: {},
    isFirstFromRace: false,
  },

  subscriptions: {
    setup({ dispatch, history }: { dispatch: any; history: any }) {
      // eslint-disable-line
    },
  },

  effects: {
    *checkIsFromRace(
      { payload }: { payload: any },
      { call, put }: { call: any; put: any }
    ) {
      const { flag } = payload;
      yield put({ type: "saveIsRaceFlag", payload: flag });
    },
    *checkIsFirstFromRace(
      { payload }: { payload: any },
      { call, put }: { call: any; put: any }
    ) {
      const { flag } = payload;
      yield put({ type: "saveIsFirstFromRaceFlag", payload: flag });
    },
    *handleRaceInfo(
      { payload }: { payload: any },
      { call, put }: { call: any; put: any }
    ) {
      const { info } = payload;
      yield put({ type: "saveRaceInfo", payload: info });
    },
    *loginSuccess(_: any, { take, put, select, call }: MethodType) {
      yield put({ type: "getCurrentBizTypeOrgList" });
      yield take("getCurrentBizTypeOrgList/@@end");
      store.set("middleFrom", "login");
      const { orgList } = yield select((_: any) => _.user);
      if (orgList && orgList.length > 1) {
        yield put({ type: "chooseOrgModalVisible", payload: true });
      } else if (orgList && orgList.length === 1) {
        const { orgId } = orgList[0];
        const result = yield call(switchActivateOrgRequest, orgId);
        if (result?.success) {
          yield put({ type: "getAccountInfo", payload: { isLogin: true } });
        }
      } else {
        // 该用户没有配置学校
        message.error("该用户没有配置场馆");
      }
    },
    *handleNoPermissions(
      { payload }: { payload: any },
      { call, put }: { call: any; put: any }
    ) {
      // // 获取账号信息，存在dva中
      const result = yield call(getUserInfo);
      if (result?.success) {
        yield put({ type: "saveAccountInfo", payload: result });
      }
    },
    *getAccountInfo(
      { payload }: { payload: any },
      { call, put }: { call: any; put: any }
    ) {
      const { isLogin } = payload;
      //满足index页面需求
      if (!isLogin) {
        const { accountInfo } = payload;
        yield put({ type: "saveAccountInfo", payload: accountInfo });
        return accountInfo;
      }
      //满足切换机构需求
      const stadiumInfo = yield call(getStadium);
      yield put({ type: "saveStadiumInfo", payload: stadiumInfo });
      // 获取账号信息，存在dva中
      const result = yield call(getUserInfo);
      if (result?.success) {
        yield put({ type: "saveAccountInfo", payload: result });
      }
      if (isLogin) {
        if (location.search.includes("lastRoute")) {
          const lastRoute = location.search.split("lastRoute=")[1];
          // 回到上一次路由
          history.push(lastRoute || "/stadium-page/stadium");
        } else {
          history.push("/stadium-page/stadium");
        }
      }
      // const { accountInfo } = payload;
      // yield put({ type: "saveAccountInfo", payload: accountInfo });
      // return accountInfo;
    },
    *getStadiumInfo(
      { payload }: { payload: any },
      { call, put }: { call: any; put: any }
    ) {
      const { stadiumInfo } = payload;
      yield put({ type: "saveStadiumInfo", payload: stadiumInfo });
    },
    *getCurrentBizTypeOrgList(_: any, { call, put }: MethodType) {
      const result = yield call(getCurrentBizTypeOrg);
      if (result) {
        if (result?.success) {
          yield put({ type: "saveBizTypeOrgList", payload: result.data });
          return true;
        } else {
          message.error(result.msg || "获取关联机构失败！");
          return true;
        }
      } else {
        return false;
      }
    },
    *switchActivateOrg(
      { payload }: { payload: any },
      { call, put, take }: MethodType
    ) {
      const { orgId, isLogin } = payload;
      const result = yield call(switchActivateOrgRequest, orgId);
      if (result && result?.success) {
        yield put({ type: "chooseOrgModalVisible", payload: false });
        if (isLogin) {
          yield put({ type: "getAccountInfo", payload: { isLogin: isLogin } });
          yield take("getAccountInfo/@@end");
        } else {
          window.location.reload();
        }
      } else {
        yield put({ type: "chooseOrgModalVisible", payload: false });
        message.error(result.msg || "切换机构失败");
      }
    },
    *getNewMenu(
      { payload }: { payload: any },
      { call, put }: { call: any; put: any }
    ) {
      const { newMenu } = payload;
      yield put({ type: "saveNewMenu", payload: newMenu });
    },
    *getDeviceTypeList(
      { payload }: { payload: any },
      { call, put }: { call: any; put: any }
    ) {
      const { list } = payload;
      yield put({ type: "saveDeviceTypeList", payload: list });
    },
  },

  reducers: {
    saveIsRaceFlag(state: any, action: any) {
      return { ...state, isFromRace: action.payload };
    },
    saveIsFirstFromRaceFlag(state: any, action: any) {
      return { ...state, isFirstFromRace: action.payload };
    },
    saveRaceInfo(state: any, action: any) {
      return { ...state, raceInfo: action.payload };
    },
    saveAccountInfo(state: any, action: any) {
      store.set("USERINFO", action.payload);
      return { ...state, accountInfo: action.payload };
    },
    saveStadiumInfo(state: any, action: any) {
      return { ...state, stadiumInfo: action.payload };
    },
    saveNewMenu(state: any, action: any) {
      return { ...state, newMenu: action.payload };
    },
    saveDeviceTypeList(state: any, action: any) {
      return { ...state, deviceTypeList: action.payload };
    },
    saveBizTypeOrgList(state: any, action: any) {
      console.log("saveBizTypeOrgList", action.payload);
      return { ...state, orgList: action.payload };
    },
    chooseOrgModalVisible(state: any, action: any) {
      console.log("chooseOrgModal", action.payload);
      return { ...state, chooseOrgModal: action.payload };
    },
  },
};
