import { message } from "antd";
import fetch from "isomorphic-fetch";
import storage from "./storage";
import history from "@/utils/history";
import { logOut } from "@/services/auth";
import store from "@/index";
import { isInQiankun } from "./qiankun";

export type IRequestMethod = "GET" | "PUT" | "POST" | "DELETE";
export interface ICheckStatusProps {
  response: Response;
  options?: any;
  url?: string;
}
interface ErrorWithResponse extends Error {
  response?: Response;
}

interface RequestOpt {
  permissions?: any;
  token: string;
  code: string;
  data: any;
  msg: null | string;
  pageNo?: number;
  pageSize?: number;
  requestId?: null | string | number;
  success: boolean;
  total?: number;
  rows?: any;
}
const toSchoolLogin = () => {
  console.log("location", location);
  if (location.origin.includes("local")) {
    return "http://local.hemabigdata.com:8000/app/campus/entrance";
  } else if (location.origin.includes("test")) {
    return "https://test.school.hemabigdata.com/app/campus/entrance";
  } else {
    return "https://school.hemabigdata.com/app/campus/entrance";
  }
  // if(location)
};
function checkStatus({ response, options, url }: ICheckStatusProps): Response {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error: ErrorWithResponse = new Error(response.statusText);
    error.response = response;
    error.message = JSON.stringify(response);

    throw error;
  }
}

/**
 * 给 URL 加上 _t=时间戳
 * @param {string} url 完整 url 或者 path
 */
function addTimestamp(url: string): string {
  // const t = `_t=${Date.now()}`;
  // const sep = url.includes("?") ? "&" : "?";
  // return url + sep + t;
  return url;
}

function parseJSON(response: Response) {
  return response.json();
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {Promise<{ data: any, err: Error }>}           An object containing either "data" or "err"
 */
// const token =
//   "Bearer eyJhbGciOiJIUzUxMiJ9.eyJmcm9tIjoiYXBwIiwidG8iOiJ1YWMtY2VudGVyIiwibG9naW5fdXNlcl9rZXkiOiI5NTIxMzM0MS02ZmNmLTQzYjktYjJkYy03YzVhNDU0ZWZlM2EifQ.ZgkpAvEthrkAIe-Or1aYZaIGLxZ-gJ1jsPEyUPWJySN2UKjAGO_XNOXOfO1M9rupszx2CD_AIsw3Z2DrUjfgOA";
export default function request(
  _url: string,
  options?: any
): Promise<RequestOpt> {
  const isFromRace = storage.get("middleFrom") === "race";
  console.log("isFromRace", isFromRace);
  const url = addTimestamp(_url);
  const defaultOptions = {
    credentials: "include",
    // redirect: 'manual',
  };
  // const Authorization = storage.get("Authorization");
  const newOptions = { ...defaultOptions, ...options };
  if (!(newOptions.body instanceof FormData)) {
    newOptions.headers = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "System-Type": "STADIUM",
      // Authorization,
      ...newOptions.headers,
    };

    newOptions.body = JSON.stringify(newOptions.body);
  } else {
    // NewOptions.body is FormData
    newOptions.headers = {
      Accept: "application/json",
      // Authorization,
      "System-Type": "STADIUM",
      ...newOptions.headers,
      // ...newOptions.body.headers,
    };
  }

  return fetch(url, newOptions)
    .then((response) =>
      checkStatus({
        response,
        options: newOptions,
        url: _url,
      })
    )
    .then(parseJSON)
    .then((data) => {
      if (data.code === 401 || data.code === "401") {
        storage.remove("STADIUMINFO");
        storage.remove("USERINFO");
        !isFromRace &&
          message.error("登录信息已过期或用户未登录，请您重新登录");
        if (isInQiankun()) {
          storage.remove("ACCOUNTINFO");
          storage.remove("SCHOOLINFO");
          window.location.href = toSchoolLogin();

          return;
        }
        if (!location.search.includes("lastRoute"))
          isFromRace
            ? history.push("/stadium-page/deletion")
            : history.push(
                "/login?lastRoute=" + location.pathname + location.search
              );
      } else if (
        data.code === "EN-0010015004" ||
        data.msg === "当前账号未绑定场馆"
      ) {
        logOut();
        storage.remove("STADIUMINFO");
        storage.remove("USERINFO");
        message.error(data.msg);
        if (isInQiankun()) {
          storage.remove("ACCOUNTINFO");
          storage.remove("SCHOOLINFO");
          window.location.href = toSchoolLogin();

          return;
        } else {
          isInQiankun()
            ? history.push("/app/campus/entrance")
            : isFromRace
            ? history.push("/stadium-page/deletion")
            : history.push("/login");
        }
      } else if ([403, "403", "EN-0010016001"].includes(data.code)) {
        console.log("无权限");
        //没有操作权限，进行对应操作
        store.dispatch({ type: "user/handleNoPermissions" });
        history.push("/stadium-page/deletion");
      }
      //  else {
      //   return data;
      // }
      return data;
    })
    .catch((err: any) => {
      if (err && err.response && err.response.status === 500) {
        // 自定义报错
        return err.response
          .json()
          .then((res: any) => {
            if (res.code === "SW-GW-1003" || res.code === "SW-00992") {
              storage.clear();
              setTimeout(() => {
                location.reload();
              }, 2000);
            } else {
              res.msg && message.error(res.msg);
              return { err: err || null };
            }
          })
          .catch((e: Error) => {
            // console.log(e);
          });
      } else if (err && err.response && err.response.status === 302) {
        return err.response
          .json()
          .then((res: any) => {
            if (res.code === 401 || res.code === "401") {
              storage.remove("STADIUMINFO");
              storage.remove("USERINFO");
              message.error("登录信息已过期或用户未登录，请您重新登录");
              if (isInQiankun()) {
                storage.remove("ACCOUNTINFO");
                storage.remove("SCHOOLINFO");
                window.location.href = toSchoolLogin();

                return;
              }
              if (!location.search.includes("lastRoute"))
                isFromRace
                  ? history.push("/stadium-page/deletion")
                  : history.push(
                      "/login?lastRoute=" + location.pathname + location.search
                    );
            } else {
              res.msg && message.error(res.msg);
              return { err: err || null };
            }
          })
          .catch((e: Error) => {
            // console.log(e);
          });
      }
      return {
        data: null,
        err: err || null,
      };
    });
}
