import "@babel/polyfill";
import "url-polyfill";
import "./public-path";
import * as Sentry from "@sentry/browser";
import dva, { DvaInstance } from "dva";
// import { createBrowserHistory } from 'history';
import history from "./utils/history";
import { message } from "antd";
import ReactDOM from "react-dom";
// @ts-ignore
import createLoading from "dva-loading";
import * as ModelSet from "./models/index";
import { SENTRY_DSN } from "@/constants";
import { loadBMapScript } from "@/utils/index";
import { isInQiankun } from "@/utils/qiankun";

import "./index.less";
import "./app.css";

export interface IDvaInstance extends DvaInstance {
  _store?: any;
}

export interface versionInfoProps {
  VERSION: string;
  ENV: "DEV" | "TEST" | "PRE" | "PROD";
  INIT: boolean;
}
declare global {
  interface Window {
    __app?: IDvaInstance;
    versionInfo: versionInfoProps;
    spm: any;
  }
}

let app: IDvaInstance | undefined;

// 本地开发、测试环境 不上报错误
if (window.versionInfo.INIT) {
  Sentry.init({
    dsn: SENTRY_DSN, // dsn链接
    environment: window.versionInfo!.ENV || "NONE", // 环境
    release:
      window.versionInfo!.VERSION.split(".").slice(0, -1).join(".") || "", // 版本号
    ignoreErrors: [
      /The play\(\) request was interrupted/,
      /Failed to load because no supported source was found/,
      /The element has no supported sources/,
      /InvalidStateError: Failed to execute 'send' on 'WebSocket': Still in CONNECTING state/,
    ],
    whitelistUrls: [],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        // 用户反馈
        // Sentry.showReportDialog({ eventId: event.event_id });
      }
      if (hint) {
        const reg =
          /The play\(\) request was interrupted | Failed to load | The element has no supported sources /;
        const result = reg.test(
          hint.data && hint.data.stack && hint.data.stack.message
        );
        if (result) {
          return null;
        }
      }
      return event;
    },
  });
}

function initApp() {
  // 1. Initialize
  app = dva({
    ...createLoading({
      effects: true,
    }),
    history: history,
    onError(error) {
      message.error(error.message);
    },
  });

  // 2. Model 迁移到路由中
  // app.model(require('./models/example'));
  // Model统一汇总到 /model/index,此处统一注入
  Object.values(ModelSet).forEach((model) => {
    app?.model(model as any);
  });
  // 3. Router
  app.router(require("./router").default);

  // 4. Start
  app.start("#root");
}

// 正常情况下作为单独的应用
// if (!isInQiankun()) {
initApp();
loadBMapScript();
// }

window.__app = app;

export default app?._store;

/**
 * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
 * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
 */
export async function bootstrap() {
  console.log("react app bootstraped");
}

/**
 * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
 */
export async function mount(props: any) {
  console.log("mount props", props);
  initApp();
  // ReactDOM.render(App.defalt, props.container ? props.container.querySelector('#root') : document.getElementById('root'));
}

/**
 * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
 */
export async function unmount(props: any) {
  ReactDOM.unmountComponentAtNode(
    props.container
      ? props.container.querySelector("#root")
      : document.getElementById("root")
  );
  // ReactDOM.unmountComponentAtNode('#root');
  // app._models.forEach(model => {
  //   app.unmodel(model.namespace);
  // });
}

/**
 * 可选生命周期钩子，仅使用 loadMicroApp 方式加载微应用时生效
 */
export async function update(props: any) {
  console.log("update props", props);
}
