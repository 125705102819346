
import history from "@/utils/history";
import { isInQiankun } from "@/utils/qiankun";
import { router } from "dva"
import React, { useEffect } from "react";
const { Redirect:DvaRedirect } = router;

function Redirect(props:router.RedirectProps) {
    useEffect(() => {
        isInQiankun() && history.push(props.to as string)
    },[])
    return isInQiankun() ?  null : <DvaRedirect {...props}></DvaRedirect>
}

export default Redirect;