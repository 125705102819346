/* eslint-disable no-use-before-define */
import { getOrderLiveMeta } from '@/services/order';
import {
  Model, createActionCreaters, Effects, Reducers,
} from '@pandaxiong1992/dva-ts-wrapper';
import {
  GlobalState,
} from './types';

export type ILargeScreenModelState = Readonly<{
  scoreLeft: number | null
  scoreRight: number | null
  player: string | null
  scoreLocation2dList: string | null
  noScoreLocation2dList: string | null
  template2dWh: string | null
}>

interface IReducersPayloads {
  save: void | Partial<ILargeScreenModelState>;
}

interface IEffectsPayloads {
  onGetMeta: void
}

const reducers: Reducers<ILargeScreenModelState, IReducersPayloads> = {
  save(state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
};

const effects: Effects<GlobalState, IEffectsPayloads> = {
  *onGetMeta({ payload }: any, { put }) {
    const res = yield getOrderLiveMeta(payload.orderId)
    if (res.success) {
      yield put({
        type: 'save',
        payload: {
          ...res.data
        }
      });
    }
  }
};

const LargeScreenModel: Model<ILargeScreenModelState, GlobalState, IReducersPayloads, IEffectsPayloads> = {
  namespace: 'largeScreen',
  state: {
    scoreLeft: 0,
    scoreRight: 0,
    player: '',
    scoreLocation2dList: '',
    noScoreLocation2dList: '',
    template2dWh: null
  },

  subscriptions: {
    setup({ dispatch, history }) {  // eslint-disable-line

    },
  },

  effects,
  reducers,
};

export default LargeScreenModel;

export const largeScreenActions = createActionCreaters(LargeScreenModel);
