import { request } from "@/utils/tool";
//赛事系统跳转后查询是否已创建活动
export function getRaceLinkOrder(data: any) {
  return request.get("/vlog/orders/getByEntity", data);
}

// 获取活动列表
export function getOrderList(data: any) {
  return request.post("/vlog/orders/lists", data);
}

// 获取活动列表（日历模式）
export function getCalendarList(data: any) {
  return request.post("/vlog/orders/calendar/lists", data);
}

// 删除活动
export function delOrder(id: number) {
  return request.delete(`/vlog/orders/${id}`);
}

// 添加活动
export function addOrder(data: any) {
  return request.post("/vlog/orders/", data);
}

// 编辑活动
export function updateOrder(data: any) {
  return request.put("/vlog/orders/", data);
}

// 获取活动直播
export function getOrderLive(data: any) {
  return request.post("/vlog/orders/play", data);
}

// 获取活动历史点击数量
export function getOrderClickNumHistory(orderId: number) {
  return request.get(`/vlog/orders/clickNum/${orderId}`);
}

// 获取直播matedata
export function getOrderLiveMeta(orderId: number) {
  return request.get(`/vlog/orders/meta/${orderId}`);
}

// 获取活动二维码
export function getWechatQrcode(data: any) {
  return request.post("/vlog/wechat/share", data);
}
//在校园中获取场地二维码
export function getSchoolWechatQrcode(data: any) {
  return request.post("/wechat/QRCode", data);
}
// 编辑周期性模版
export function updateOrderTask(data: any) {
  return request.put("/vlog/orders/task", data);
}

// 获取活动是否有待开始/进行中
export function getOrderStatus(id: number) {
  return request.get(`/vlog/orders/hasOrder/${id}`);
}

// 结束活动
export function finishOrder(id: number) {
  return request.get(`/vlog/orders/end/${id}`);
}

// 获取可用的场地列表
export function getVenueAvailable(data: any) {
  return request.post("/vlog/venues/available", data);
}

// 判断模版后面是否还有活动可能建
export function getTaskIsLast(data: any) {
  return request.get("/vlog/orders/task/last", data);
}

// 删除模版
export function delTask(data: any) {
  return request.delete("/vlog/orders/task/del", data);
}
//队伍列表
export function getTeamList(data: any) {
  return request.get(`/vlog/teams/list`, data);
}
//删除队伍
export function deleteTeam(id: any) {
  return request.delete(`/vlog/teams/${id}`);
}
