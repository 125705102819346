/* eslint-disable no-use-before-define */
import {
  Model, createActionCreaters, Effects, Reducers,
} from '@pandaxiong1992/dva-ts-wrapper';
import { Moment } from 'moment-recur-ts';
import {
  GlobalState,
} from './types';

export type IOrderModelState = Readonly<{
  visible: boolean
  orderId?: number
  taskId?: number
  gridVenueId?: number
  gridTime?: [Moment, Moment]
}>

interface IReducersPayloads {
  save: void | Partial<IOrderModelState>;
}

interface IEffectsPayloads {
  onChangeVisible: void
  onChangeGridSelect: void
}

const reducers: Reducers<IOrderModelState, IReducersPayloads> = {
  save(state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
};

const effects: Effects<GlobalState, IEffectsPayloads> = {
  *onChangeVisible({ payload }: any, { put }) {
    yield put({
      type: 'save',
      payload
    });
  },

  *onChangeGridSelect({ payload }: any, { put }) {
    yield put({
      type: 'save',
      payload
    });
  }
};


const OrderModel: Model<IOrderModelState, GlobalState, IReducersPayloads, IEffectsPayloads> = {
  namespace: 'order',
  state: {
    visible: false,
  },

  subscriptions: {
    setup({ dispatch, history }) {  // eslint-disable-line

    },
  },

  effects,
  reducers,
};

export default OrderModel;

export const orderActions = createActionCreaters(OrderModel);
