import store from "@/utils/storage";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Input, message, Modal, Form, Button, Menu } from "antd";
import React, { useState, useEffect } from "react";
import MHeader from "../Base/Header";
import styles from "./index.less";
import history from "@/utils/history";
import { logOut, reqRetrievePassword } from "@/services/auth";
import { connect } from "dva";
import { account } from "../../models/routes";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import ChooseOrgModal from "../ChooseOrgModal";

type PageHeaderProps = {};

const PageHeader: React.FC<PageHeaderProps> = (props: any) => {
  const { dispatch, orgList } = props;
  const [show, setShow] = useState(false);
  const [isShowAccountManage, setIsShowAccountManage] = useState(false);
  const [formRef] = Form.useForm();
  const [logOutModal, setLogOutModal] = useState(false);
  //退出登录
  const exit = async () => {
    try {
      const res = await logOut();
      if (res.success) {
        store.remove("STADIUMINFO");
        store.remove("USERINFO");
        store.remove("_t");
        message.success("退出成功");
        history.push("/login");
      } else {
        res.msg ? message.error(res.msg) : message.error("退出失败");
      }
    } catch (error) {
      message.error("网络异常");
    }
  };
  // const [items, setItems] = useState<ItemType[] | undefined>([]);
  //修改密码
  const editPassword = async (data: {
    oldPassword: string;
    newPassword: string;
  }) => {
    const res = await reqRetrievePassword(data);
    if (res.success) {
      setShow(false);
      history.push("/login");
      message.info("修改密码成功，请重新登录！");
    } else {
      res.msg ? message.error(res.msg) : message.error("修改密码失败");
    }
  };
  const handleSubmit = async (value: any) => {
    await editPassword(value);
  };
  const onCancel = () => {
    formRef.resetFields();
    setShow(false);
  };
  useEffect(() => {
    const isShow = store
      .get("USERINFO")?.
      permissions?.includes("stadium:account:page");
    setIsShowAccountManage(isShow);
  }, [props.accountInfo]);
  const menu = (
    <Menu>
      {isShowAccountManage ? (
        <Menu.Item
          onClick={() => history.push("/stadium-page/account")}
          key="1"
        >
          <img
            style={{ width: "16px", marginRight: "8px", height: "16px" }}
            src="https://hema-web.oss-cn-hangzhou.aliyuncs.com/schoolapp/%E8%B4%A6%E5%8F%B7%E7%AE%A1%E7%90%86.png"
          ></img>
          <div>账号管理</div>
        </Menu.Item>
      ) : null}
      <Menu.Item
        onClick={() => {
          setShow(true);
        }}
        key="2"
      >
        <img
          style={{ width: "16px", marginRight: "8px", height: "16px" }}
          src="https://hema-web.oss-cn-hangzhou.aliyuncs.com/schoolapp/%E4%BF%AE%E6%94%B9%E5%AF%86%E7%A0%81.png"
        ></img>
        <div>修改密码</div>
      </Menu.Item>
      {orgList.length && orgList.length > 1 ? (
        <Menu.Item
          onClick={() => {
            dispatch({
              type: "user/getCurrentBizTypeOrgList",
            }).then((res: boolean) => {
              dispatch({
                type: "user/chooseOrgModalVisible",
                payload: res,
              });
            });
          }}
          key="3"
        >
          <img
            style={{ width: "16px", marginRight: "8px", height: "16px" }}
            src="https://hema-web.oss-cn-hangzhou.aliyuncs.com/school/campus/switch-org-icon.png"
          ></img>
          <div>切换机构</div>
        </Menu.Item>
      ) : null}
      <Menu.Item onClick={() => setLogOutModal(true)} key="4">
        <img
          style={{ width: "16px", marginRight: "8px", height: "16px" }}
          src="https://hema-web.oss-cn-hangzhou.aliyuncs.com/school/campus/logout-icon.png"
        ></img>
        <div>退出登录</div>
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    dispatch({
      type: "user/getCurrentBizTypeOrgList",
    });
  }, []);
  return (
    <div className={styles.header}>
      <img src="https://hema-web.oss-cn-hangzhou.aliyuncs.com/stadiums/resource/%E7%9F%A9%E5%BD%A2%402x%20(7).png" />

      <MHeader
        left={
          <span className={styles.orgName}>
            {store.get("USERINFO")?.user?.orgName || "未知场馆"}
          </span>
        }
        right={!props.isFromRace?
          <Dropdown
            // menu={{ items }}
            overlay={menu}
            overlayClassName={styles.headerDropDown}
            trigger={["click"]}
          >
            <div className={styles.name}>
              <span className={styles.userName}>
                {store.get("USERINFO")?.user?.nickName || "未知用户"}
              </span>
              <DownOutlined translate={undefined} />
            </div>
          </Dropdown>:null
        }
      />
      <Modal
        closable={false}
        title="修改密码"
        open={show}
        onOk={handleSubmit}
        onCancel={onCancel}
        footer={null}
      >
        <Form form={formRef} onFinish={handleSubmit} labelCol={{ span: 4 }}>
          <Form.Item
            label="旧密码"
            name="oldPassword"
            rules={[{ required: true, message: "请输入旧密码" }]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="newPassword"
            rules={[{ required: true, message: "请输入新密码" }]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item style={{ textAlign: "right", width: "100%" }}>
            <Button onClick={onCancel}>取消</Button>
            <Button
              type={"primary"}
              htmlType="submit"
              style={{ marginLeft: 16 }}
            >
              确定
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        destroyOnClose={true}
        closable={false}
        open={logOutModal}
        cancelText="取消"
        okText="确定"
        onCancel={() => {
          setLogOutModal(false);
        }}
        onOk={() => {
          exit();
        }}
        width={410}
      >
        <p style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ width: "22px", height: "22px" }}
            src="https://hema-web.oss-cn-hangzhou.aliyuncs.com/school/campus/red-warning.png"
          />
          <span
            style={{
              fontSize: 16,
              color: "#1f2e4d",
              marginLeft: 12,
              fontWeight: 600,
            }}
          >
            退出登录
          </span>
        </p>
        <p style={{ fontSize: 14, color: "#3d4966", marginLeft: 34 }}>
          确认注销并退出登录吗？
        </p>
      </Modal>
      <ChooseOrgModal closable={true} />
    </div>
  );
};
const PageHeaderState = (state: any) => {
  return {
    accountInfo: state?.user.accountInfo,
    orgList: state?.user.orgList,
    isFromRace:state?.user.isFromRace
  };
};
export default connect(PageHeaderState)(PageHeader);
