import React from "react";
import PropTypes from "prop-types";
import { router, routerRedux } from "dva";
import dynamic from "dva/dynamic";
import moment from "moment";
import "moment/locale/zh-cn";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import App from "./routes/app";
import Error from "./routes/error";
// import { connect } from "dva";
// import AuthRoute from "./components/AuthRoute";
import store from "./utils/storage";
const dynamic = require("dva").dynamic;
const { Switch, Route } = router;
import Redirect from "./components/Redirect";
import { isInQiankun } from "./utils/qiankun";

const { ConnectedRouter } = routerRedux;

moment.locale("zh-cn");
interface IRoutersProps {
  history: any;
  app: any;
}

const Routers: React.FC<IRoutersProps> = ({ history, app }) => {
  // const error = dynamic({
  //   app,
  //   component: () => Ai,
  // } as any);
  const routes = isInQiankun()
    ? [
        {
          path: "/stadium-page",
          exact: false,
          component: () =>
            isInQiankun()
              ? import("./routes/qiankunIndex/index")
              : import("./routes/index/index"),
        },
        {
          exact: false,
          path: "/error",
          component: () => import("./routes/error"),
        },
        {
          path: "/stadium-h5",
          exact: false,
          component: () => import("./routes-h5/index/index"),
        },
      ]
    : [
        {
          path: "/stadium-page",
          exact: false,
          component: () =>
            isInQiankun()
              ? import("./routes/qiankunIndex/index")
              : import("./routes/index/index"),
        },
        {
          path: "/login",
          component: () => import("./routes/login/index"),
        },
        {
          path: "/middle-page",
          component: () => import("./routes/middlePage/index"),
        },
        {
          path: "/error",
          component: () => import("./routes/error"),
        },
        // {
        //   path: "/no-permission",
        //   component: () => import("./routes/error/noPermission"),
        // },
        {
          path: "/stadium-h5",
          exact: false,
          component: () => import("./routes-h5/index/index"),
        },
      ];

  return (
    <ConfigProvider locale={zhCN}>
      <ConnectedRouter history={history}>
        <App>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <Redirect
                  to={
                    store.get("STADIUMINFO")?.coverUrl
                      ? "/stadium-page"
                      : "/login"
                  }
                />
              )}
            />

            {routes.map(({ path, exact, ...dynamics }: any, key) => (
              <Route
                key={path}
                exact={exact !== false}
                path={path}
                component={
                  dynamic({
                    app,
                    ...dynamics,
                  } as any) as any
                }
              />
            ))}

            <Route component={Error as any} />
          </Switch>
        </App>
      </ConnectedRouter>
    </ConfigProvider>
  );
};

Routers.propTypes = {
  history: PropTypes.object,
  app: PropTypes.object,
};

export default Routers;
