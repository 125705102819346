import { getStadiumInfo, logOut } from "@/services/auth";
import { message } from "antd";
// import { StadiumInfo } from "typings/types";
import storage from "./storage";
import history from "@/utils/history";
import store from "@/index";

const getStadium = () => {
  const isFromRace = storage.get("middleFrom") === "race";
  return new Promise(
    (resolve, reject) => {
      getStadiumInfo()
        .then((res) => {
          if (!res) {
            reject();
            return;
          }
          if (res?.success) {
            storage.set("STADIUMINFO", res.data || {});
            resolve(res.data);
          } else if (res.code === "410") {
            isFromRace &&
              message.error(
                "您当前登录的账号未绑定相关场馆，请切换账号重新登录！"
              );
            isFromRace
              ? history.push("/stadium-page/deletion")
              : history.push("/login");
            reject();
          } else if (
            res.code === "EN-0010015004" ||
            res.msg === "当前帐号未绑定场馆"
          ) {
            message.error(res.msg);
            logOut();
            reject();
          } else {
            message.error(res.msg || "获取场馆信息失败！");
            reject();
          }
        })
        .catch((err) => {
          message.error(err?.message || "获取场馆信息失败！");
          reject(err);
        });
    }

    // resolve(stadiumInfo);
    // }
    // }
  );
};

export default getStadium;
