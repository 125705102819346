import { IS_DEV, IS_TEST } from "@/constants";
import { getOSSSignature } from "@/services/auth";
import { Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import React, { Component } from "react";
import { OSSDataInfo } from "typings/types";
import store from "@/utils/storage";

import { debounce } from "lodash";

type UploadComponentState = {
  OSSData: OSSDataInfo;
  imageUrl: string;
};

interface UploadComponentProps {
  onChangeFile?: (file: UploadFile<any>[]) => void;
  onBeforeUpload?: (file: RcFile) => Promise<void> | void;
  type?: string | undefined;
  useType?: string | undefined;
}

class OSSUpload extends Component<UploadComponentProps, UploadComponentState> {
  constructor(props: UploadComponentProps) {
    super(props);
    this.state = {
      OSSData: {},
      imageUrl: "",
    };
  }

  fetchOSSData = () => {
    return new Promise<void>(async (resolve) => {
      const { data } = await getOSSSignature({
        type: this.props.useType,
        stadiumId: store.get("STADIUMINFO")?.id,
      });

      this.setState(
        {
          OSSData: data || {},
        },
        resolve
      );
    });
  };

  // transformFile = (file: any) => {
  // const { OSSData } = this.state;
  // const suffix = file.name.slice(file.name.lastIndexOf("."));
  // const filename = Date.now() + suffix;
  // file.url = `${OSSData.dir}${filename}`;
  //   return file;
  // };

  getExtraData = (file: any) => {
    const { OSSData } = this.state;
    return {
      key: OSSData.dir + file.url,
      OSSAccessKeyId: OSSData?.accessid,
      policy: OSSData?.policy,
      Signature: OSSData?.signature,
    };
  };

  beforeUpload = (file: any) => {
    const { OSSData } = this.state;
    const suffix = file.name.slice(file.name.lastIndexOf("."));
    const filename = Date.now() + suffix;
    file.url = `${OSSData.dir}${filename}`;
    return new Promise<void>(async (resolve, reject) => {
      const { onBeforeUpload } = this.props;
      const { OSSData } = this.state;
      const expire = OSSData.expire ? Number(OSSData.expire) * 1000 : 0;

      if (onBeforeUpload) {
        try {
          await onBeforeUpload(file);
        } catch (error) {
          return reject();
        }
      }

      if (expire < Date.now()) {
        await this.fetchOSSData();
      }
      resolve();
    });
  };
  getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  onChange = debounce(({ file }: UploadChangeParam<UploadFile<any>>) => {
    const { OSSData } = this.state;
    const { host, dir } = OSSData;
    console.log("file", file);
    if (!file.url?.includes("http")) {
      file.url = `${IS_DEV || IS_TEST ? host : OSSData.host}/${OSSData.dir}${
        file.url
      }`;
    }
    if (file.status === "done") {
      this.getBase64(file.originFileObj, (imageUrl: string) => {
        file.baseData = imageUrl;
        const { onChangeFile } = this.props;
        if (onChangeFile) {
          onChangeFile([file]);
        }
      });
    }
  }, 500);

  render() {
    const { OSSData } = this.state;

    return (
      <Upload
        {...this.props}
        name="file"
        action={OSSData.host}
        showUploadList={false}
        // transformFile={this.transformFile}
        data={this.getExtraData}
        beforeUpload={this.beforeUpload}
        onChange={this.onChange}
      >
        {this.props.children}
      </Upload>
    );
  }
}

export default OSSUpload;
